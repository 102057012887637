// user album name & image storage
let user = {
  albumName: '',
  albumPhoto: null,
  hasAlbumName: function () {
    return this.albumName && this.albumName.length > 0;
  },
  hasCorrectAlbumName: function () {
    return this.albumName && this.albumName.length < 14;
  },
  hasNoSpecialChars: function () {
    let reg = /^[a-zàäâéèêëïîöôùüû\s]*$/i;
    return reg.test(this.albumName);
  },
  hasAlbumPhoto: function () {
    return this.albumPhoto != null;
  }
}

export {user};
