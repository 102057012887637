import "jquery/dist/jquery.slim.min";
import {navigator} from "./navigator";

let initNavigatorButtonsListeners = function () {
  $(function () {

    $('.js-btn-go-home').on('click', function (event) {
      if (event.target.id && event.target.id === 'restart-button') {
        $('#stickers .sticker-selector').first().trigger('click');
      }
      navigator.goto(navigator.steps.home);
    });

    $('#create-button, #close-add-picture').on("click", function () {
      navigator.goto(navigator.steps.chooseAlbumName);
    });

    $('#validate-choose-name, #close-congrats').on("click", function () {
      navigator.goto(navigator.steps.chooseAlbumPhoto);
    });

    $('#add-picture-validate').on("click", function () {
      navigator.goto(navigator.steps.congratulations);
    });

  });
}

export {initNavigatorButtonsListeners};
