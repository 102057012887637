import flowtype from "flowtype-js";

let fitText = function () {
  let matches = document.querySelectorAll(".name");
  for (let z in matches) {
    if (matches.hasOwnProperty(z) && matches[z]) {
      flowtype(matches[z], {
        fontRatio: 5.5,
      });
    }
  }
};

export {fitText};
