import "jquery/dist/jquery.slim.min";
import {user} from "./user";

let initAlbumNameListeners = function () {
  $(function () {

    $(".inText").on("input", function () {
      let op = $(".output-pochette");
      op.text($(this).val());
      user.albumName = $(this).val();

      // Required for flowtype re-draw
      window.dispatchEvent(new Event('resize'));
    });

  });
}

export {initAlbumNameListeners};
