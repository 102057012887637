import "jquery/dist/jquery.slim.min"
import jsCookie from "js-cookie/src/js.cookie";

let googleUA = 'UA-129893734-1';
window.gtag = function () {
};

let cookiesManager = {
  implemented: false,
  init: function () {
    let cookie = jsCookie.get('stats');
    if (cookie !== '0' && cookie !== '1') {
      this.displayCookiesBanner();
    } else {
      $('#cookies').hide();
    }

    if (cookie === '1') {
      this.implementTracking();
    }

  },
  displayCookiesBanner: function () {
    jsCookie.remove('stats');

    $(function () {
      $('#cookies').show();

      // Accept cookies
      $('#cookies-accept').on("click", function () {
        jsCookie.set('stats', '1');
        $('#cookies').hide();
        cookiesManager.implementTracking();
      });

      // Refuse cookies
      $('#cookies-dismiss').on("click", function () {
        jsCookie.set('stats', '0');
        $('#cookies').hide();
      });
    });
  },
  implementTracking: function () {
    if (!this.implemented) {
      this.implementGoogleTracking();
      this.implementPixelTracking();
      this.implemented = true;
    }
  },
  implementGoogleTracking: function () {
    let script = '<script async src="https://www.googletagmanager.com/gtag/js?id=' + googleUA + '"></script>';
    let initScript = '<script>' +
      'window.dataLayer = window.dataLayer || [];' +
      'function gtag() {' +
      'dataLayer.push(arguments);' +
      '}' +
      'gtag("js", new Date());' +
      'gtag("config", "' + googleUA + '");' +
      '</script>';

    $('body').append(script).append(initScript);
  },
  implementPixelTracking: function () {
    let script = '<script id="cidsyncjs" src="https://pixel-static.spotify.com/sync.min.js" async></script><noscript><img id="cidmgmt" src="https://pixel.spotify.com/v1/sync?nojs=1" style="display: none;" width="0" height="0" /></noscript>';
    $('body').append(script);
  }
}

export {cookiesManager};



