import '../index.html';
import '../favicon.ico';
import '../svg/icons.svg'
import '../stylesheets/global.scss';
import {initAlbumImageListeners} from './album-image';
import {initAlbumNameListeners} from './album-name';
import {fitText} from './fit-text';
import {navigator} from './navigator';
import {cookiesManager} from "./cookies";

const ENV = {
  PROD: 'production',
  DEV: 'dev',
};

const productionHosts = [
  "macoverjuliendore.withspotify.com",
  "www.macoverjuliendore.withspotify.com",
  "macoverjuliendore.fr",
  "www.macoverjuliendore.fr",
]

let app = {
  env: ENV.DEV,
};

const siteHost = window.location.host;
if (productionHosts.includes(siteHost)) {
  app.env = ENV.PROD;
}

window.log = function (...args) {
  if (app.env === ENV.DEV) {
    console.log(...args);
  }
};

$(function () {
  cookiesManager.init();

  // NAVIGATOR
  navigator.init();
  navigator.goto(navigator.steps.home);

  // ALBUM LISTENERS INIT
  initAlbumNameListeners();
  initAlbumImageListeners();

  //Fit Text
  fitText();
});
