let croppieModal = {
  modal: $('#modal-croppie'),
  modalContent: $('#modal-croppie .modal-content'),

  show: function () {
    $(this.modal).show();
  },
  hide: function () {
    this.modal.hide();
  }
}

export {croppieModal}
