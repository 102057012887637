let imageManager = {
  croppie: null,
  croppieConfig: {
    enforceBoundary: true,
    showZoomer: true,
    url: "",
    enableExif: true,
    enableZoom: true,
    viewport: {
      width: 300,
      height: 300,
      type: 'circle'
    },
    boundary: {
      width: 300,
      height: 300
    }
  },
  image: null
};

export {imageManager};
