import "jquery/dist/jquery.slim.min";
import {user} from './user';
import {initNavigatorButtonsListeners} from "./navigator-buttons";

let navigator = {
  steps: {
    init: "INIT",
    home: "HOME",
    chooseAlbumName: "CHOOSE_ALBUM_NAME",
    chooseAlbumPhoto: "CHOOSE_ALBUM_PHOTO",
    congratulations: "CONGRATULATIONS",
  },

  init: function () {
    initNavigatorButtonsListeners();
  },

  goto: function (label) {
    log("navigator.goto :: " + label);

    switch (label) {

      case navigator.steps.home:
        this.showHome();
        this.hideMainContent();
        break;

      case navigator.steps.chooseAlbumName:
        this.hideAllSteps();
        this.hideHome();
        this.showMainContent();
        $('#choose-name').show();
        break;

      case navigator.steps.chooseAlbumPhoto:
        // check album name not null
        if (!user.hasAlbumName()) {
          alert('Veuillez saisir un nom d\'album');
          return;
          // check album name < 15
        } else if (!user.hasCorrectAlbumName()) {
          alert('Veuillez saisir un nom d\'album inférieur à 15 caractères.');
          return;
        } else if (!user.hasNoSpecialChars()) {
          alert('Veuillez saisir un nom d\'album sans caractères spéciaux');
          return;
        }

        this.hideAllSteps();
        this.hideHome();
        this.showMainContent();
        $('#add-picture').show();
        break;

      case navigator.steps.congratulations:
        this.hideAllSteps();
        this.hideHome();
        this.showMainContent();
        $('#congrats').show();
        break;

      default:
        this.showHome();
        this.hideMainContent();
    }

    // Required for flowtype re-draw
    window.dispatchEvent(new Event('resize'));

  },

  hideAllSteps: function () {
    $('#main-content > .bloc-content').hide();
  },

  showHome: function () {
    $('#home').show();
    $('body').removeClass('active');
  },

  hideHome: function () {
    $('#home').hide();
  },

  showMainContent: function () {
    $('#main-content').show();
    $('body').addClass('active');
  },

  hideMainContent: function () {
    $('#main-content').hide();
    $('body').removeClass('active');
  }

};

export {navigator};
