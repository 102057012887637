import "jquery/dist/jquery.slim.min";
import {user} from "./user";
import Croppie from "croppie";
import {croppieModal} from "./croppie-modal"
import {imageManager} from "./image-manager"

const axios = require('axios')

/**function handleImage(e) {
  log('handleImage', e);
  if (e.target.value.length === 0) {
    $('#loading').hide();
  } else {
    $('#loading').show();
    let reader = new FileReader();
    reader.onload = function (event) {
      applyImageToCanvas(event.target.result.toString());
    }
    reader.readAsDataURL(e.target.files[0]);
    user.albumPhoto = e.target.files[0];
    $('#add-picture-validate').removeClass('required');
    $('#modify-picture-validate').removeClass('required');
  }
}*/

function cancelCroppieModal() {
  croppieModal.hide();
}

function userImageSubmitted(e) {
  croppieModal.show();

  let reader = new FileReader();

  reader.onload = function (e) {
    if (imageManager.croppie) {
      imageManager.croppie.destroy();
    }
    imageManager.croppie = new Croppie(document.getElementById('croppie'), imageManager.croppieConfig);
    imageManager.croppie.bind({url: e.target.result}).catch(function (event) {
      console.log(event)
    });
  }

  reader.readAsDataURL(e.target.files[0]);
  user.albumPhoto = e.target.files[0];
}

function validateCrop() {
  imageManager.croppie.result({type: 'canvas', size: 'viewport', circle: true}).then(function (c) {
    // applyImageToCanvas(c.toString(), false);
    $('.sticker.user > a.sticker-selector').data('full', c.toString()).trigger('click').find(' > img').attr('src', c.toString());
    displayUserImageThumbButton();
    croppieModal.hide();
  });
}

function selectDefaultSticker() {
  $('#stickers .sticker-selector').first().trigger('click');
}

function displayUserImageThumbButton() {
  $('.sticker.user').show();
  $('#btn-pick-image').hide();
}

function hideUserImageThumbButton() {
  $('.sticker.user').hide();
  $('#btn-pick-image').show();
}

function removeUserImage() {
  selectDefaultSticker();
  hideUserImageThumbButton();
}

function applyImageToCanvas(src, fit = false) {
  const outputImageAspectRatio = 1;
  let canvas = $("canvas.picture");
  log(canvas);
  let img = new Image(canvas.width(), canvas.height());
  img.onload = function () {
    const inputWidth = img.naturalWidth;
    const inputHeight = img.naturalHeight;
    img.width = img.naturalWidth;
    img.height = img.naturalHeight;
    const inputImageAspectRatio = inputWidth / inputHeight;

    log('Loaded image width : ' + inputWidth);
    log('Loaded image height : ' + inputHeight);
    log('Loaded image ratio : ' + inputImageAspectRatio);

    for (let z in canvas) {
      if (canvas.hasOwnProperty(z) && canvas[z].getContext && typeof canvas[z].getContext !== "undefined") {
        let c = canvas[z];
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;

        if (fit) {

          if (inputImageAspectRatio > outputImageAspectRatio) {
            outputWidth = inputHeight * outputImageAspectRatio;
          } else if (inputImageAspectRatio < outputImageAspectRatio) {
            outputHeight = inputWidth / outputImageAspectRatio;
          }

          log('Output image width : ' + outputWidth);
          log('Output image height : ' + outputHeight);

          // calculate the position to draw the image at
          let outputX = (outputWidth - inputWidth) * .5;
          let outputY = (outputHeight - inputHeight) * .5;

          log('Output image x : ' + outputX);
          log('Output image y : ' + outputY);
        }

        c.width = outputWidth;
        c.height = outputHeight;
        let ctx = c.getContext('2d');
        //if(fit) {

        let scaleRatio = ctx.canvas.width / Math.max(outputWidth, outputHeight);
        log('scaleRatio : ' + scaleRatio)

        // Store the current transformation matrix
        ctx.save();

        // Use the identity matrix while clearing the canvas
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        // Restore the transform
        ctx.restore();
        //}

        ctx.drawImage(img, 0, 0);
        $('#loading').hide();
      }
    }
  }
  img.src = src;
}

function downloadImage(canvas) {
  let link = document.createElement('a');
  link.download = user.albumName.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '_album.png';
  link.href = canvas.toDataURL()
  link.click();

  console.log(user);

  $('#loading').hide();
}

function stickerSelected(event) {
  $('#add-picture').find('.sticker.selected').removeClass('selected');
  $(this).parent().addClass('selected');
  applyImageToCanvas($(this).data('full'), false);
}

function initAlbumImageListeners() {
  $(function () {
    let inputFile = $('#imageLoader');
    // Link btn to trigger input file click
    $('#btn-pick-image').on('click', function (event) {
      if ($(event.target).attr('id') !== inputFile.attr('id')) {
        inputFile.trigger('click');
      }
    });

    $('#btn-change-image').on('click', function (event) {
      inputFile.trigger('click');
    });

    // Listen input file change event
    //inputFile.on('change', handleImage);
    inputFile.on('change', userImageSubmitted);

    // Listen sticker choice
    $('.sticker-selector').on('click', stickerSelected);

    // Validate crop
    $('#btn-validate-croppie').on("click", validateCrop);
    $('#btn-cancel-croppie, #modal-croppie .backdrop').on("click", cancelCroppieModal);
    $(document).on('keyup', function (e) {
      if (e.key === "Escape" && $('#modal-croppie:visible')) { // escape key maps to keycode `27`
        cancelCroppieModal();
      }
    });
    $('#clear-user-image-btn').on('click', removeUserImage);

    $('#download').on('click', function (event) {
      event.preventDefault();
      axios({
        method: 'post',
        url: "/download.php",
        crossDomain: true,
        responseType: "blob",
        data: {
          image: document.getElementById('final').toDataURL(),
          name: user.albumName
        }
      }).then(function (response) {
        console.log(response);

        let link = document.createElement('a');
        link.download = user.albumName.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '_album.png';
        link.href = URL.createObjectURL(response.data);
        link.click();
      })
        .catch(function (error) {
          alert('Une erreur a eu lieu, merci de re-essayer');
          console.error(error);
        })
        .then(function () {
          // always executed
        });
    });

    selectDefaultSticker();

  });
}

export {initAlbumImageListeners};

